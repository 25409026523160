import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className='Footer'>
        <p className='Footer-p'>Developed by  <span className='Footer-span'>Arjun TR</span></p>
    </div>
  )
}

export default Footer